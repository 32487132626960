import CryptoJS from "crypto-js"

export const createError = (msg, status) => {
	const err = new Error(msg)
	err.name = "custom"
	err.status = 404
	throw err
}

export const buildFormData = (request) => {
	const formData = new FormData()
	Object.keys(request).forEach((key) => {
		formData.append(key, JSON.stringify(request[key]))
	})
	formData.append("brodontencrypt", 1)

	return formData
}

export const mapApiError = (error) => {
	console.warn(error)
	if (error.name === "custom") {
		return {
			data: error.message,
			code: error.status,
		}
	}

	const response = error.response

	if (response === undefined) {
		return {
			data: "CORS or network",
			code: 500,
		}
	}

	const data = response.data
	const status = response.status || data.error.status
	let message = (data.error && data.error.message) || ""

	if (!data.result) {
		switch (status) {
			case 500:
				message = "Ошибка сервера"
				break
			case 400:
				message = "Ошибка валидации"
				break
			case 401:
				message = "Ошибка авторизации"
				break
			case 404:
				message = "Не найдено"
				break
			default:
				break
		}

		return {
			data: message,
			code: status,
		}
	}
}

export const halloweenProduct = (id) => ["7", "8", "9"].indexOf(id) > -1

export const lightButtons = (id) =>
	["34", "35", "36", "37", "41", "45", "46", "47", "49"].indexOf(id) > -1

export const halloweenProductPremium = (id) => ["11", "12", "13"].indexOf(id) > -1
export const xMasProduct = (id) => ["14", "15", "16"].indexOf(id) > -1
export const chinaProduct = (id) => ["38", "39", "40"].indexOf(id) > -1

export const isNewYearProduct = (id) => ["17", "18", "19", "20", "21"].indexOf(id) > -1
export const startProduct = (id) => ["22", "23", "32"].indexOf(id) > -1
export const easterProduct = (id) => ["24", "25", "26", "27", "28"].indexOf(id) > -1
export const eMbaProduct = (id) => ["29", "30", "31"].indexOf(id) > -1

export const mapData = (data, decrypt = false) => {
	if (decrypt) {
		const decrypted = CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(data))
		data = JSON.parse(decrypted)
	}

	return data.data
}

// const key = "6g83zKNShmZcYE747WaLuKdzyMNspM4Y"
// const iv = String.fromCharCode(
//   0x0,
// )

// const ciphertextWA = CryptoJS.enc.Base64.parse(data)
// const keyWA = CryptoJS.enc.Utf8.parse(key)
// const ivWA = CryptoJS.enc.Utf8.parse(iv)
// const ciphertextCP = { ciphertext: ciphertextWA }

// console.log({ ciphertextWA }, { ivWA }, { keyWA })
// let decrypted = CryptoJS.AES.decrypt(ciphertextCP, keyWA, { iv: ivWA, mode: CryptoJS.mode.CBC })
// let decrypted2 = decrypted.toString(CryptoJS.enc.Utf8)

// console.log({ decrypted2 })
